/* eslint-disable boundaries/element-types */
import { useProfile } from '~/entities/profile'

import { useAuth } from '~/features/auth'

import { ApiResponseError } from './ApiResponseError'
import { type BaseMeta } from './BaseMeta'
import { type BaseRequestParams } from './BaseRequestParams'
import { HTTPHeaders } from './HTTPHeaders'

export function useUFetch(baseURL: string) {
  const getContentType = (body: unknown, isFormData?: boolean) => {
    const { applicationJson, urlEncoded } = HTTPHeaders.contentType
    if (isFormData) {
      return null
    }
    if (body !== null && body !== 'null' && typeof body === 'string') {
      try {
        JSON.parse(body)
        return applicationJson
      } catch {
        return urlEncoded
      }
    }
    return applicationJson
  }
  const fetcher = async <T>(params: BaseRequestParams): Promise<{ data: T; meta: BaseMeta }> => {
    const { endpoint, method, body, formData } = params
    const { applicationJson } = HTTPHeaders.contentType
    const headers = new Headers({
      Accept: applicationJson
    })
    const contentType = getContentType(params.body, params.formData)
    if (contentType) {
      headers.set('Content-Type', contentType)
    }

    const fullURL = `${baseURL}${endpoint.toString()}`

    const result: { data: Nullable<T>; meta: BaseMeta } = {
      data: null,
      meta: {
        page: 1,
        total: 1,
        totalPages: 1,
        perPage: 1
      }
    }
    const fetchFn = async (retry = true) => {
      const { accessToken } = storeToRefs(useProfile())
      if (accessToken.value) {
        headers.set('Authorization', `Bearer ${accessToken.value}`)
      }
      const response = await fetch(fullURL, {
        method,
        headers,
        body: formData ? (body as FormData) : JSON.stringify(body)
      })
      const parsedResponse = await response.text()
      const { status: statusCode, headers: resHeaders } = response

      result.meta = {
        page: resHeaders.get('X-Page') ? Number(resHeaders.get('X-Page')) : 1,
        total: resHeaders.get('X-Total') ? Number(resHeaders.get('X-total')) : 1,
        totalPages: resHeaders.get('X-Total-Pages') ? Number(resHeaders.get('X-Total-Pages')) : 1,
        perPage: resHeaders.get('X-Per-Page') ? Number(resHeaders.get('X-Per-Page')) : 1
      }
      try {
        result.data = JSON.parse(parsedResponse)
      } catch {
        result.data = JSON.parse(JSON.stringify({ data: parsedResponse, statusCode })) // для кейсов когда на 200 возвращается строка
      }
      if (response.status === 401 && retry) {
        const { renewToken } = useAuth()
        const { renewIsInProgress } = storeToRefs(useAuth())
        if (renewIsInProgress.value) {
          watchOnce(renewIsInProgress, async () => {
            await fetchFn(false)
          })
        } else {
          await renewToken()
          await fetchFn(false)
        }
        return
      }
      if (!response.ok) {
        const { status, statusText: text } = response
        throw new ApiResponseError({ status, text, body: result })
      }
    }

    await fetchFn()

    return result as { data: T; meta: BaseMeta }
  }

  return {
    fetcher
  }
}
