import { useRouteStore } from '~/shared/lib'

import { useProfile } from '~/entities/profile'

import { useAuth } from '~/features/auth'

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.env.VITEST_ENV) {
    return
  }

  if (to.path !== '/login') {
    const { savePreviousRoute } = useRouteStore()
    savePreviousRoute(from)
  }
  const { signInCallback, getUser } = useAuth()
  const user = await getUser()
  const { code, scope, state } = to.query
  const router = useRouter()
  const profileStore = useProfile()
  const { isLoggedIn, role } = storeToRefs(profileStore)
  const { setupUser, resetUser } = profileStore

  if (user && !isLoggedIn.value) {
    await setupUser(user)
  }

  if (!user && isLoggedIn.value) {
    resetUser()
  }

  if (to.meta.adminRoute && role.value !== 'ADMIN') {
    return router.push('/')
  }

  if (to.path === '/login') {
    if (user) {
      return router.push('/')
    }
    if (code && scope && state) {
      const config = useRuntimeConfig()
      await signInCallback(`${config.public.OPENID_HOST}/${to.fullPath}`)
      return router.push('/')
    }
    return
  }
  if (!user) {
    return router.push('/login')
  }
})
