import { useCorpFetcher } from '~/shared/lib'

import { type UserProfileRoleOut } from '~/entities/profile'

// eslint-disable-next-line boundaries/element-types
import { getAttachments, type Attachment } from '~/features/attachments'

export const getCurrentUserProfile = async () => {
  const { data } = await useCorpFetcher<UserProfileRoleOut>({
    endpoint: '/passport.idm.Idm/GetCurrentUser',
    method: 'POST'
  })
  let attachments: Attachment[] = []
  if (data.attachments) {
    try {
      attachments = await getAttachments({
        ids: data.attachments,
        variant: {
          resize: {
            method: 'RESIZE_METHOD_FILL',
            width: 200,
            height: 200
          }
        },
        disposition: 'DISPOSITION_INLINE'
      })
    } catch (e) {
      console.log(e)
    }
  }
  data.avatar = attachments[0]?.variantUrl

  return data
}
