import { useUFetch, type BaseRequestParams } from '~/shared/lib'

type IntranetService = 'attachemnts'

export const useIntranetFetcher = <T>(service: IntranetService, params: BaseRequestParams) => {
  const config = useRuntimeConfig()
  let path = config.public.intranetGateway + '/intranet'
  if (service === 'attachemnts') {
    path += '.attachments.Attachments/'
  }

  const { fetcher } = useUFetch(path)

  return fetcher<T>(params)
}
