import { type User, UserManager, type UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts'
import { acceptHMRUpdate } from 'pinia'

import { useResetStores } from '~/shared/lib'

import { useProfile } from '~/entities/profile'

import { useRuntimeConfig } from '#app'

export const useAuth = defineStore('auth', () => {
  const userManager = ref<Nullable<UserManager>>(null)
  const initializeOIDC = () => {
    try {
      const config = useRuntimeConfig()
      const settings: UserManagerSettings = {
        authority: config.public.openidHost,
        client_id: config.public.openidClientId,
        redirect_uri: `${config.public.host}/login`,
        silent_redirect_uri: `${config.public.host}/silent-renew`,
        post_logout_redirect_uri: window.location.origin,
        scope: 'openid profile offline_access',
        response_type: 'code',
        userStore: new WebStorageStateStore({ prefix: 'uplatform_corp_' }),
        loadUserInfo: true,
        automaticSilentRenew: true
      }
      userManager.value = new UserManager(settings)
    } catch (error) {
      console.log(error)
    }
  }

  initializeOIDC()

  const signInRedirect = () => {
    if (userManager.value) {
      try {
        return userManager.value.signinRedirect()
      } catch (e) {
        console.log(e)
      }
    }
  }

  const signInCallback = async (path: string) => {
    if (userManager.value) {
      try {
        return userManager.value.signinCallback(path)
      } catch (e) {
        console.log(e)
        signOut()
      }
    }
  }

  const renewIsInProgress = ref(false)
  const renewToken = async (): Promise<void> => {
    const profileStore = useProfile()

    const { setupUser } = profileStore
    if (userManager.value && !renewIsInProgress.value) {
      try {
        renewIsInProgress.value = true
        const user = await userManager.value.signinSilent()
        if (user) {
          await setupUser(user)
        }
      } catch (error) {
        console.log(error)
        await signOut()
      } finally {
        renewIsInProgress.value = false
      }
    }
  }

  const getUser = (): Promise<User | null> | void => {
    if (userManager.value) {
      try {
        return userManager.value.getUser()
      } catch (e) {
        console.log(e)
      }
    }
  }

  const signOut = async () => {
    if (userManager.value) {
      useResetStores()
      await userManager.value.removeUser()
      await userManager.value.clearStaleState()
      navigateTo('/login')
    }
  }
  const $reset = () => {
    initializeOIDC()
  }

  return {
    signInRedirect,
    signInCallback,
    renewToken,
    getUser,
    signOut,
    $reset,
    renewIsInProgress,
    userManager
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuth, import.meta.hot))
}
