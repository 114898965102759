import { useIntranetFetcher } from '~/shared/lib'

import { type Attachment, type GetAttachmentsParams } from '~/features/attachments'

export const getAttachments = async (params: GetAttachmentsParams): Promise<Attachment[]> => {
  const { data } = await useIntranetFetcher<{ attachments: Attachment[] }>('attachemnts', {
    method: 'POST',
    endpoint: 'ListAttachments',
    body: params
  })
  return typeof data.attachments === 'undefined' ? [] : data.attachments
}
