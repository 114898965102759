import { default as _91_91page_93_938FkyPr5Rc4Meta } from "/app/pages/balance/history/[[page]].vue?macro=true";
import { default as control_45feedbackbR2zTu23tLMeta } from "/app/pages/control-feedback.vue?macro=true";
import { default as _91_91page_93_93U4wJ2LAD3dMeta } from "/app/pages/events/[[page]].vue?macro=true";
import { default as indexrj3vfe4n20Meta } from "/app/pages/events/create-item/index.vue?macro=true";
import { default as indextB4zgUb9GJMeta } from "/app/pages/events/item=[id]/edit/index.vue?macro=true";
import { default as indexvO13vKgP4tMeta } from "/app/pages/faq/create-item/index.vue?macro=true";
import { default as indexvax6lLCvOtMeta } from "/app/pages/faq/edit-item=[id]/index.vue?macro=true";
import { default as indexkGMs4DN35HMeta } from "/app/pages/faq/index.vue?macro=true";
import { default as feedbackOc9gyz0hXBMeta } from "/app/pages/feedback.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as infoRUNMKy7QPmMeta } from "/app/pages/info.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as _91_91page_93_93SJ7GKfzOBVMeta } from "/app/pages/my-orders/[[page]].vue?macro=true";
import { default as _91_91page_93_93W7pPpC8u5dMeta } from "/app/pages/news/[[page]].vue?macro=true";
import { default as indexMuyYPJkQfJMeta } from "/app/pages/news/create-item/index.vue?macro=true";
import { default as indexMSzIrS25uEMeta } from "/app/pages/news/item=[id]/edit/index.vue?macro=true";
import { default as indexYUVitL5U9zMeta } from "/app/pages/news/item=[id]/index.vue?macro=true";
import { default as _91_91page_93_93zWH04JWgF6Meta } from "/app/pages/profiles/[[page]].vue?macro=true";
import { default as index4i2JMJQL6DMeta } from "/app/pages/profiles/item=[id]/index.vue?macro=true";
import { default as roles_45controlpZ6P7UPm5ZMeta } from "/app/pages/roles-control.vue?macro=true";
import { default as _91_91page_93_93n7yTtxqvTyMeta } from "/app/pages/shop/[[page]].vue?macro=true";
import { default as indexhirmaDstrNMeta } from "/app/pages/shop/cart/index.vue?macro=true";
import { default as indexbnJA6EOA25Meta } from "/app/pages/shop/create-item/index.vue?macro=true";
import { default as indexxRVahvXfnfMeta } from "/app/pages/shop/edit-item=[id]/index.vue?macro=true";
import { default as indexo8odJNwXafMeta } from "/app/pages/shop/item=[id]/index.vue?macro=true";
import { default as silent_45renewu9ryZsK1ffMeta } from "/app/pages/silent-renew.vue?macro=true";
import { default as tags_45controlTCzbK2TDX4Meta } from "/app/pages/tags-control.vue?macro=true";
export default [
  {
    name: "balance-history-page",
    path: "/balance/history/:page?",
    component: () => import("/app/pages/balance/history/[[page]].vue").then(m => m.default || m)
  },
  {
    name: "control-feedback",
    path: "/control-feedback",
    meta: control_45feedbackbR2zTu23tLMeta || {},
    component: () => import("/app/pages/control-feedback.vue").then(m => m.default || m)
  },
  {
    name: "events-page",
    path: "/events/:page?",
    component: () => import("/app/pages/events/[[page]].vue").then(m => m.default || m)
  },
  {
    name: "events-create-item",
    path: "/events/create-item",
    meta: indexrj3vfe4n20Meta || {},
    component: () => import("/app/pages/events/create-item/index.vue").then(m => m.default || m)
  },
  {
    name: "events-item=id-edit",
    path: "/events/item=:id()/edit",
    meta: indextB4zgUb9GJMeta || {},
    component: () => import("/app/pages/events/item=[id]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: "faq-create-item",
    path: "/faq/create-item",
    meta: indexvO13vKgP4tMeta || {},
    component: () => import("/app/pages/faq/create-item/index.vue").then(m => m.default || m)
  },
  {
    name: "faq-edit-item=id",
    path: "/faq/edit-item=:id()",
    meta: indexvax6lLCvOtMeta || {},
    component: () => import("/app/pages/faq/edit-item=[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/app/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "feedback",
    path: "/feedback",
    component: () => import("/app/pages/feedback.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/app/pages/info.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginhHM0vSTW5jMeta || {},
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "my-orders-page",
    path: "/my-orders/:page?",
    component: () => import("/app/pages/my-orders/[[page]].vue").then(m => m.default || m)
  },
  {
    name: "news-page",
    path: "/news/:page?",
    component: () => import("/app/pages/news/[[page]].vue").then(m => m.default || m)
  },
  {
    name: "news-create-item",
    path: "/news/create-item",
    meta: indexMuyYPJkQfJMeta || {},
    component: () => import("/app/pages/news/create-item/index.vue").then(m => m.default || m)
  },
  {
    name: "news-item=id-edit",
    path: "/news/item=:id()/edit",
    meta: indexMSzIrS25uEMeta || {},
    component: () => import("/app/pages/news/item=[id]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: "news-item=id",
    path: "/news/item=:id()",
    component: () => import("/app/pages/news/item=[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "profiles-page",
    path: "/profiles/:page?",
    component: () => import("/app/pages/profiles/[[page]].vue").then(m => m.default || m)
  },
  {
    name: "profiles-item=id",
    path: "/profiles/item=:id()",
    component: () => import("/app/pages/profiles/item=[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "roles-control",
    path: "/roles-control",
    meta: roles_45controlpZ6P7UPm5ZMeta || {},
    component: () => import("/app/pages/roles-control.vue").then(m => m.default || m)
  },
  {
    name: "shop-page",
    path: "/shop/:page?",
    component: () => import("/app/pages/shop/[[page]].vue").then(m => m.default || m)
  },
  {
    name: "shop-cart",
    path: "/shop/cart",
    component: () => import("/app/pages/shop/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-create-item",
    path: "/shop/create-item",
    meta: indexbnJA6EOA25Meta || {},
    component: () => import("/app/pages/shop/create-item/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-edit-item=id",
    path: "/shop/edit-item=:id()",
    meta: indexxRVahvXfnfMeta || {},
    component: () => import("/app/pages/shop/edit-item=[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-item=id",
    path: "/shop/item=:id()",
    component: () => import("/app/pages/shop/item=[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "silent-renew",
    path: "/silent-renew",
    component: () => import("/app/pages/silent-renew.vue").then(m => m.default || m)
  },
  {
    name: "tags-control",
    path: "/tags-control",
    component: () => import("/app/pages/tags-control.vue").then(m => m.default || m)
  }
]