<script setup lang="ts">
  import { useUplatformLocale } from '~/features/locale'
  const { updateLocale } = useUplatformLocale()

  updateLocale()
</script>
<template>
  <div class="app-container">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
<style scoped lang="scss">
  .app-container {
    background-color: $grey;
  }
</style>

<style lang="scss">
  @include disableQuasarFormatting;
  .app-container {
    position: relative;
  }
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    background-color: $grey;
  }
</style>
