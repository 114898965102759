import type { RouteLocationNormalized } from 'vue-router'

const STACK_LENGTH = 20

export const useRouteStore = defineStore('navigationHistory', () => {
  const previousRoutesStack = ref<RouteLocationNormalized[]>([])

  function savePreviousRoute(route: RouteLocationNormalized) {
    previousRoutesStack.value.push(route)
    if (previousRoutesStack.value.length >= STACK_LENGTH) {
      previousRoutesStack.value.shift()
    }
  }
  const findClosestRoute = (routeName: string) => {
    const len = previousRoutesStack.value.length

    if (!len) {
      return undefined
    }

    let result
    for (let i = len - 1; i >= 0; i--) {
      if (previousRoutesStack.value[i].name === routeName) {
        result = previousRoutesStack.value[i]
        break
      }
    }

    return result
  }
  const $reset = () => {
    previousRoutesStack.value = []
  }
  return {
    previousRoutesStack,
    $reset,
    findClosestRoute,
    savePreviousRoute
  }
})

export const useNavigationHistory = () => {
  const routeStore = useRouteStore()
  const { findClosestRoute, savePreviousRoute } = routeStore
  const { previousRoutesStack } = storeToRefs(routeStore)

  return {
    findClosestRoute,
    savePreviousRoute,
    previousRoutesStack
  }
}
