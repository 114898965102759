import { type ErrorStatus } from './ErrorStatus'

export class ApiResponseError<T> {
  status: ErrorStatus
  text: string
  body: T

  constructor(response: ApiResponseError<T>) {
    Object.assign(this, response)
  }
}
