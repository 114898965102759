export const UPLATFORM_LOCALE_KEY = 'uplatform_corp_portal_lang'
export const useUplatformLocale = () => {
  const savedLocale = useCookie(UPLATFORM_LOCALE_KEY)
  const { locale } = useI18n()
  const dayjs = useDayjs()
  const updateLocale = () => {
    if (savedLocale.value) {
      locale.value = savedLocale.value
    } else {
      locale.value = window?.navigator.language.slice(0, 2)
      savedLocale.value = locale.value
    }
    dayjs.locale(locale.value)
  }
  const setLocale = (val: 'ru' | 'en') => {
    savedLocale.value = val
    locale.value = val
    dayjs.locale(locale.value)
  }
  return {
    updateLocale,
    setLocale
  }
}
