
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'

import 'dayjs/locale/en'
import 'dayjs/locale/ru'
import pluralGetSet from 'dayjs/plugin/pluralGetSet'
import localeData from 'dayjs/plugin/localeData'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'


dayjs.extend(updateLocale)

dayjs.extend(pluralGetSet)
dayjs.extend(localeData)
dayjs.extend(relativeTime)
dayjs.extend(utc)



// defaultLocale: undefined



export default dayjs
