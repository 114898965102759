import type { Integration } from '@sentry/types'
import * as Sentry from '@sentry/vue'
import type { Router } from 'vue-router'

import { defineNuxtPlugin, useRuntimeConfig } from '#app'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const router = nuxtApp.$router as Router
  nuxtApp.vueApp.config.errorHandler = (err) => {
    console.error('Vue Error', err)
  }
  const integrations: Integration[] = []
  if (String(config.public.metricsPerformance) === 'true') {
    integrations.push(
      Sentry.browserTracingIntegration({
        router
      })
    )
  }
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    app: nuxtApp.vueApp,
    // debug: config.public.ENV !== 'production',
    dsn: config.public.sentryDsn,
    // release: config.public.SENTRY_RELEASE,
    // environment: config.public.SENTRY_ENVIRONMENT,
    integrations,
    logErrors: true,
    attachStacktrace: true,
    enableTracing: true,
    trackComponents: true,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1
  })

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException
    }
  }
})
