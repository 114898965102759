import { type User } from 'oidc-client-ts'
import { acceptHMRUpdate } from 'pinia'

import { type UserProfileRoleOut, getCurrentUserProfile } from '~/entities/profile'

import { type Role } from './Roles'
export const useProfile = defineStore('profile', () => {
  const profile = ref<Nullable<UserProfileRoleOut>>(null)
  const role = computed<Nullable<Role>>(() => profile.value?.role || null)
  const isAdmin = computed(() => role.value === 'ADMIN')
  const accessToken = ref('')
  const corpId = computed(() => profile.value?.id || '')
  const profileIsLoading = ref(false)

  const isLoggedIn = computed(() => !!accessToken.value)

  const setupUser = async (user: User) => {
    accessToken.value = user.access_token
    try {
      await fetchCurrentUserProfile()
    } catch (e) {
      console.warn(e)
    }
  }

  const fetchCurrentUserProfile = async () => {
    try {
      profileIsLoading.value = true
      profile.value = await getCurrentUserProfile()
    } catch (e) {
      console.log(e)
    } finally {
      profileIsLoading.value = false
    }
  }

  const resetUser = () => {
    profile.value = null
  }
  const firstName = computed(() => profile.value?.firstName || '')
  const lastName = computed(() => profile.value?.lastName || '')
  const fullName = computed(() => `${firstName.value} ${lastName.value}`)
  const $reset = () => {
    accessToken.value = ''
    profile.value = null
    profileIsLoading.value = false
  }
  return {
    fetchCurrentUserProfile,
    setupUser,
    resetUser,
    $reset,
    accessToken,
    isLoggedIn,
    profile,
    isAdmin,
    role,
    corpId,
    fullName,
    profileIsLoading
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProfile, import.meta.hot))
}
